<template>
  <b-row
    ><b-col cols="12" class="text-center my-3"
      ><p class="green text-uppercase bold larger">
        {{ $t("events.olympics.medal_bonus.headline") }}
      </p>
      <p class="text-uppercase green normalsize" v-html="subline"></p></b-col
    ><b-col cols="6" class="d-flex flex-column justify-content-between"
      ><div class="container-fluid">
        <b-row no-gutters align-h="between" class="medal-info-row plain"
          ><b-col cols="2"
            ><span class="text-uppercase text-grey">{{
              $t("events.olympics.medals>other")
            }}</span></b-col
          ><b-col
            ><span class="text-uppercase text-grey">{{
              $t("events.olympics.medal_bonus.addition_bonus")
            }}</span></b-col
          ></b-row
        ><b-row no-gutters class="medal-info-row top"
          ><b-col cols="3" class="d-inline-flex align-items-center"
            ><div class="medal gold"></div>
            <span class="bold single-letters">=</span></b-col
          ><b-col
            ><div class="w-100 d-inline-flex align-items-center">
              <div class="icon gold mr-3"></div>
              <span class="text-golden bold single-letters mr-3">{{
                "&"
              }}</span>
              <div class="icon crown"></div>
              <span class="blue bold single-letters">*</span>
            </div>
            <div class="w-100 text-left">
              <span class="text-uppercase medium"
                >x
                {{ $t("events.olympics.medal_bonus.number_of_medals") }}</span
              >
            </div></b-col
          ></b-row
        ><b-row no-gutters class="medal-info-row"
          ><b-col cols="3" class="d-inline-flex align-items-center"
            ><div class="medal silver"></div>
            <span class="bold single-letters">=</span></b-col
          ><b-col
            ><div class="w-100 d-inline-flex align-items-center">
              <span class="text-golden single-letters mr-3">+20</span>
              <div class="icon crown"></div>
              <span class="blue bold single-letters">*</span>
            </div>
            <div class="w-100 text-left">
              <span class="text-uppercase medium">
                {{ $t("events.olympics.medal_bonus.per_medal") }}</span
              >
            </div></b-col
          ></b-row
        ><b-row no-gutters class="medal-info-row bottom"
          ><b-col cols="3" class="d-inline-flex align-items-center"
            ><div class="medal bronze"></div>
            <span class="bold single-letters">=</span></b-col
          ><b-col
            ><div class="w-100 d-inline-flex align-items-center">
              <span class="text-golden single-letters mr-3">+1.000.000</span>
              <div class="icon gold"></div>
              <span class="blue single-letters bold">**</span>
            </div>
            <div class="w-100 text-left">
              <span class="text-uppercase medium">
                {{ $t("events.olympics.medal_bonus.per_medal") }}</span
              >
            </div></b-col
          ></b-row
        ><b-row no-gutters align-v="center" align-h="between" class="mt-5"
          ><span class="blue text-uppercase footnotes medium"
            >*{{ $t("events.olympics.medal_bonus.purchase_required") }}</span
          ><span class="blue text-uppercase medium footnotes"
            >**{{
              $t("events.olympics.medal_bonus.purchase_not_required")
            }}</span
          ></b-row
        >
      </div></b-col
    ><b-col cols="6" class="d-flex flex-column justify-content-between"
      ><div class="container-fluid text-left">
        <b-row no-gutters class="medal-info-row plain"
          ><span class="text-grey text-uppercase">
            {{ $t("events.olympics.medal_bonus.example") }}</span
          >
        </b-row>
        <b-row no-gutters class="medal-info-row plain p-0"
          ><span class="text-uppercase bold text-white">
            {{ $t("events.olympics.medal_bonus.package_purchase") }}</span
          >
        </b-row>
        <b-row
          no-gutters
          align-h="start"
          align-v="center"
          :style="{ 'font-size': '36px' }"
          class="pb-0"
        >
          <span class="text-golden bold mr-3">300.000.000</span>
          <div class="icon gold mr-5"></div>
          <span class="text-golden bold mr-3">150</span>
          <div class="icon crown"></div>
        </b-row>
        <b-row no-gutters class="medal-info-row plain pb-0"
          ><span class="text-uppercase text-white bold">
            {{ $t("events.olympics.medal_bonus.won_medals") }}</span
          >
        </b-row>
        <b-row
          no-gutters
          align-v="center"
          align-h="between"
          class="medal-info-row plain"
          ><b-col
            cols="3"
            class="d-inline-flex align-items-center justify-content-between"
            ><span class="text-white bold bigger">2 x</span>
            <div class="medal gold small"></div>
            <span class="text-white bold bigger">=</span></b-col
          ><b-col class="d-flex flex-column ml-1"
            ><div class="d-inline-flex align-items-center">
              <span class="text-golden bold">+600.000.000 </span>
              <div class="icon gold small ml-1"></div>
            </div>
            <span class="text-grey light">(300.000.000 x 2)</span></b-col
          ><b-col cols="3" class="d-flex flex-column"
            ><div class="d-inline-flex align-items-center">
              <span class="text-golden bold">+300</span>
              <div class="icon crown small"></div>
            </div>
            <span class="text-grey light">(150 x 2)</span></b-col
          ></b-row
        ><b-row
          no-gutters
          align-v="center"
          align-h="between"
          class="medal-info-row plain"
          ><b-col
            cols="3"
            class="d-inline-flex align-items-center justify-content-between"
            ><span class="text-white bold bigger">2 x</span>
            <div class="medal silver small"></div>
            <span class="text-white bold bigger">=</span></b-col
          ><b-col class="d-flex flex-column ml-1"
            ><div class="d-inline-flex align-items-center">
              <span class="text-golden bold">+40</span>
              <div class="icon crown small"></div>
            </div>
            <span class="text-grey light">(20 x 2)</span></b-col
          ></b-row
        ><b-row no-gutters align-v="center" class="medal-info-row plain"
          ><b-col
            cols="3"
            class="d-inline-flex align-items-center justify-content-between"
            ><span class="text-white bold bigger">2 x</span>
            <div class="medal bronze small"></div>
            <span class="text-white bold bigger">=</span> </b-col
          ><b-col class="d-flex flex-column ml-1"
            ><div class="d-inline-flex align-items-center">
              <span class="text-golden bold">+2.000.000 </span>
              <div class="icon gold small ml-1"></div>
            </div>
            <span class="text-grey light">(1.000.000 x 2)</span></b-col
          ></b-row
        ><b-row no-gutters class="medal-info-row plain pb-0"
          ><span class="text-white text-uppercase light-blue-glow">{{
            $t("events.olympics.medal_bonus.total_bonus")
          }}</span></b-row
        >
        <b-row
          no-gutters
          align-h="start"
          align-v="center"
          :style="{ 'font-size': '36px' }"
          ><b-col class="d-flex flex-column">
            <div class="d-inline-flex align-items-center">
              <span class="text-golden bold mr-3">+602.000.000</span>
              <div class="icon gold mr-3"></div>
            </div>
            <span class="text-grey light">(600.000.000 + 2.000.000)</span>
          </b-col>
          <b-col cols="4" class="d-flex flex-column"
            ><div class="d-inline-flex align-items-center">
              <span class="text-golden bold mr-3">+340</span>
              <div class="icon crown"></div>
            </div>
            <span class="text-grey light">(300 + 40)</span>
          </b-col>
        </b-row>
      </div></b-col
    ></b-row
  >
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage4",
  computed: {
    subline() {
      return this.$t("events.olympics.medal_bonus.subline");
    },
  },
};
</script>
<style scoped lang="scss">
.text-grey {
  color: #524749;
  font-family: Ubuntu-medium;
  font-size: 21px;
  line-height: 1;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  &.light {
    color: #8e8e8e;
  }
}
.icon {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 32px;

  &.gold {
    background-image: url(~@/assets/img/common/coin1.png);
    width: 32px;
    &.small {
      width: 24px;
      height: 24px;
    }
  }
  &.crown {
    background-image: url(~@/assets/img/common/crown6.png);
    width: 43px;
    &.small {
      width: 32px;
      height: 24px;
    }
  }
}
.medal-info-row {
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 2px solid black;
  border-top: 2px solid #4f4f4f;
  &.top {
    border-top: none;
  }
  &.bottom {
    border-bottom: none;
  }
  &.plain {
    border: none;
  }
  .text-white {
    font-size: 21px;
    &.bigger {
      font-size: 26px;
    }
  }
  .text-golden {
    font-size: 28px;
  }

  .medal {
    width: 71px;
    height: 100px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 10px;
    &.gold {
      background-image: url(~@/assets/img/events/gfg-medal-gold.png);
    }
    &.silver {
      background-image: url(~@/assets/img/events/gfg-medal-silver.png);
    }
    &.bronze {
      background-image: url(~@/assets/img/events/gfg-medal-bronze.png);
    }
    &.small {
      width: 39px;
      height: 54px;
    }
  }
  .single-letters {
    font-size: 36px;
    font-family: Ubuntu-bold;
  }
  .text-left {
    font-size: 28px;
  }
}
</style>
